<template>
  <div class="d-flex justify-content-center">
    <b-col
      cols="12"
      md="4"
    >
      <b-card>
        <div class="contact-box center-version">
          <div class="d-flex align-items-center flex-column">

            <div>
              {{ transaction }}
              <b-avatar
                size="100"
                :src="transaction.image"
                :text="avatarText(transaction.user_name)"
                class="mb-2"
              />
            </div>
            <h3 class="mb-2">
              <strong>{{ transaction.user_name }} </strong>
            </h3>

          </div>
          <div class="product_gifts-info">
            <ul>

              <li>
                <feather-icon
                  icon="MailIcon"
                /> Email: {{ transaction.user_email }}
              </li>
              <li>
                <feather-icon
                  icon="PhoneIcon"
                /> Mobile : {{ transaction.user_mobile }}
              </li>
              <li>
                <feather-icon
                  icon="CalendarIcon"
                /> Date : {{ transaction.created_at | formatDate }}
              </li>
              <li>
                <feather-icon
                  icon="ColumnsIcon"
                /> Message Category: {{ transaction.category_name_ar }} -  {{ transaction.category_name_en }}
              </li>
              <li>
                <feather-icon
                  icon="ColumnsIcon"
                /> Message Details : {{ transaction.message }}
              </li>

            </ul>
          </div>
        </div>
      </b-card>

    </b-col>
  </div>

</template>

<script>
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
  },

  setup() {
    const { router } = useRouter()
    const transaction = ref({})
    const columns = [
      { key: 'user_name', label: 'User Name' },
      { key: 'image', label: 'Image' },

    ]

    return {
      columns,
      router,
      transaction,
      avatarText,

    }
  },
  mounted() {
  },
  methods: {

  },
}
</script>

  <style scoped lang="scss">
  .padd span {
    padding: 2px;
  }
  .change-status {
    cursor: pointer;
  }
  .product_gifts-info ul {
  list-style: none;
  li {
    padding-top: 1rem;
  }
}
  </style>
